import * as _ from 'lodash'
import {
  convertPreset,
  fetchPreset,
  enhanceConfigByRole,
  enhanceStructreWithSnapshot,
  getFormControllerType,
} from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { undoable } from '../utils'
import { FormPreset } from '../../../constants/form-types'
import { EVENTS } from '../../../constants/bi'
import {
  ComponentRef,
  FormSnapshot,
  ControllerType,
  ComponentConfig,
  RawComponentStructre,
  ComponentStructre,
} from '../api-types'
import { ROLE_FORM, FIELDS } from '../../../constants/roles'
import CoreApi from '../core-api'
import { APP_CONTROLLER_DEFINITION, APP_WIDGET_DEFINITION } from './controller-definition'
import { MASTER_PAGE } from './constants'
import { FormPlugin } from '../../../constants/plugins'
import { NOTIFICATION_EVENTS } from '../../constans/EVENTS'
import { findPlugin } from '../plugins/utils'
import Experiments from '@wix/wix-experiments'
import { SHOULD_CREATE_COLLECTION } from '../preset/presets-data'
import { ADI, ADI_TEMPLATE } from '../../../constants/initiators'

const normalizeFormName = (
  formNames: string[],
  nameFromPreset: string | undefined,
  presetKey: FormPreset
) => {
  const title =
    nameFromPreset ||
    translations.t('formName', {
      name: translations.t(`addForm.templates.${presetKey}.label`),
    })

  return createSuffixedName(formNames, title)
}

const isADI = initiator => _.isEmpty(initiator) || initiator === ADI
const isTemplateADI = initiator => initiator === ADI_TEMPLATE
const addedFormsPromisesContainer = {}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: Experiments
  private ravenInstance

  constructor(boundEditorSDK, coreApi, { biLogger, experiments, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  @undoable()
  public addForm(preset: FormPreset, payload = {}, formSnapshot?: FormSnapshot) {
    return this._addForm(preset, payload, formSnapshot)
  }

  public async addFormWithCollection(
    preset: FormPreset,
    payload = {},
    formSnapshot?: FormSnapshot
  ): Promise<{ formComponentRef: ComponentRef }> {
    const formComponentRef = await this.addForm(
      preset,
      { ...payload, createCollection: true },
      formSnapshot
    )

    return {
      formComponentRef,
    }
  }

  public async addAppWidget(pageRef: ComponentRef, containerDefinition): Promise<ComponentRef> {
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: _.get(containerDefinition, 'data.components') || [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          id: dataItemIdPlaceholder,
          controllerType: ControllerType.WIX_FORMS,
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  public updateFormCollectionId(
    formCompRef: ComponentRef,
    oldCollectionId?: string
  ): Promise<null> {
    if (!oldCollectionId) {
      return
    }
    const compIdAndRealCollectionId = oldCollectionId.split('_')
    if (compIdAndRealCollectionId.length <= 1) {
      return
    }
    const realCollectionId = compIdAndRealCollectionId[1]
    const newCollectionId = `${formCompRef.id}_${realCollectionId}`
    return this.coreApi.setComponentConnection(formCompRef, { collectionId: newCollectionId })
  }

  public waitForAddedForm(formCompRef: ComponentRef): Promise<undefined> {
    return addedFormsPromisesContainer[formCompRef.id]
  }

  public async createAutoCollection(componentRef: ComponentRef): Promise<string> {
    await this.coreApi.saveSite()
    const extraBiData = {
      request_type: 'auto',
    }
    return this.coreApi.createCollection(componentRef, extraBiData)
  }

  private _saveSiteIfNecessary(formRef: ComponentRef): Promise<null> {
    const installedFromAppMarket = !!!formRef // we want to manual save only for app market installation
    return installedFromAppMarket ? this.coreApi.saveSiteIfUnsaved() : Promise.resolve()
  }

  private async _runPostAddFormBasedPluginBehavior({
    selectForm,
    controllerRef,
    formRef,
  }: {
    selectForm: boolean
    controllerRef: ComponentRef
    formRef: ComponentRef
  }) {
    const {
      config: { plugins },
    } = await this.coreApi.getComponentConnection(formRef)
    if (findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)) {
      this.coreApi.managePanels.openGetSubscribersFirstTimePanel()
    }

    if (!findPlugin(plugins, FormPlugin.REGISTRATION_FORM) && selectForm) {
      this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }

    if (findPlugin(plugins, FormPlugin.PAYMENT_FORM)) { //tslint:disable-line
      this.coreApi.popNotificationAction({
        // TODO: Move to manage panels
        componentRef: formRef,
        plugins,
        notificationTrigger: NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED,
      })
    }

    if (findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)) { //tslint:disable-line
      await this.coreApi.steps.updateMultiStepFormTitles(formRef)
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [formRef],
      })
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }
  }

  private async _createFormConfig(
    formConfig: ComponentConfig,
    presetName: FormPreset
  ): Promise<ComponentConfig> {
    const formName = await this._getFormName(_.get(formConfig, 'formName'), presetName)
    const msid = await this.coreApi.getMetaSiteId()
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    let emailId = ''

    try {
      emailId = await this.coreApi.getOwnerEmailId()
    } catch (err) {}
    return {
      ...formConfig,
      formName,
      msid,
      formLabelId,
      emailId,
      labels: [...formConfig.labels, formLabelId],
    }
  }

  private async _enhanceField(
    fieldConfig: ComponentConfig,
    customFields?: string[]
  ): Promise<ComponentConfig> {
    if (!customFields) {
      return fieldConfig
    }

    const customFieldId = await this.coreApi.fields.getCustomFieldForField(customFields, {
      connectionConfig: fieldConfig,
    })

    if (customFieldId) {
      return { ...fieldConfig, customFieldId }
    }

    return fieldConfig
  }

  private async _addFormEditor({
    presetName,
    formRef,
    selectForm = true,
    createCollection = false,
    rawPreset,
  }: {
    presetName: FormPreset
    formRef: ComponentRef | null
    selectForm: boolean
    createCollection: boolean
    rawPreset: RawComponentStructre
  }): Promise<ComponentRef> {
    const installedFromAppMarket = !formRef
    const { structure: formStructure, ancestors } = await this._createFormStructureEditor({
      presetName,
      rawPreset,
      formRef,
    })

    if (!installedFromAppMarket) {
      await this.boundEditorSDK.selection.deselectComponents({ compsToDeselect: ancestors[0] })
      await this.boundEditorSDK.components.remove({
        componentRef: ancestors[0],
      })
    }

    const { addedFormRef, controllerRef } = await this._addFormStructureEditor({
      formStructure,
      ancestors,
    })

    this._afterFormAddedEditor({
      controllerRef,
      selectForm,
      addedFormRef,
      installedFromAppMarket,
      presetName,
      createCollection,
    })

    return addedFormRef
  }

  private async _createFormStructureEditor({
    presetName,
    rawPreset,
    formRef,
  }: {
    presetName: FormPreset
    rawPreset: RawComponentStructre
    formRef: ComponentRef
  }): Promise<{ structure: ComponentStructre; ancestors: ComponentRef[] }> {
    const structure = await this._createDynamicFormDataEditor({ presetName, rawPreset })
    const ancestors = formRef
      ? await this.boundEditorSDK.components.getAncestors({
          componentRef: formRef,
        })
      : []

    const appWidgetStructre = formRef
      ? await this.boundEditorSDK.components.serialize({
          componentRef: ancestors[0],
        })
      : _.merge({}, APP_WIDGET_DEFINITION, {
          layout: { x: 175, y: 0 },
          data: { controllerType: getFormControllerType(structure) },
        })

    return {
      structure: convertPreset(structure, {
        controllerId: 'placeholder-id',
        appWidgetStructre,
        coords: _.pick(appWidgetStructre.layout, ['x', 'y']),
      }),
      ancestors,
    }
  }

  private async _createDynamicFormDataEditor({
    presetName,
    rawPreset,
  }: {
    presetName: FormPreset
    rawPreset: RawComponentStructre
  }): Promise<RawComponentStructre> {
    const roleEnhancmentMap = {
      [ROLE_FORM]: config => this._createFormConfig(config, presetName),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureEditor({
    formStructure,
    ancestors,
  }: {
    formStructure: ComponentStructre
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef; controllerRef: ComponentRef }> {
    const pageRef = ancestors[1] || (await this.boundEditorSDK.pages.getCurrent())
    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: formStructure,
      pageRef,
    })

    const addedFormRef = (await this.boundEditorSDK.components.getChildren({
      componentRef: controllerRef,
    }))[0]
    return { addedFormRef, controllerRef }
  }

  private _afterFormAddedEditor({
    controllerRef,
    selectForm,
    addedFormRef,
    installedFromAppMarket,
    presetName,
    createCollection,
  }: {
    controllerRef: ComponentRef
    selectForm: boolean
    addedFormRef: ComponentRef
    installedFromAppMarket: boolean
    presetName: FormPreset
    createCollection: boolean
  }) {
    this.coreApi.appState.setState([controllerRef])
    this._runPostAddFormBasedPluginBehavior({
      selectForm,
      controllerRef,
      formRef: addedFormRef,
    })

    const shouldCreateCollection =
      (installedFromAppMarket && SHOULD_CREATE_COLLECTION[presetName]) || createCollection

    // TODO: When 'specs.cx.FormBuilderCreateAutoCollection' will be merge move this implementation to AddFormWithCollection
    // After all presets will be with collection we won't need installedFromAppMarket check and SHOULD_CREATE_COLLECTION[preset] inside createAutoCollection
    // so this side effect should not be inside this api
    addedFormsPromisesContainer[addedFormRef.id] = new Promise(async resolve => {
      if (shouldCreateCollection) {
        const extraBiData = { request_type: 'auto' }
        await this.coreApi.createCollection(addedFormRef, extraBiData)
        resolve()
      } else {
        await this.coreApi.editDraft(addedFormRef)
        resolve()
      }
    })
  }

  private async _addFormADI({
    formRef,
    formSnapshot,
    rawPreset,
    presetName,
    createCustomFields,
  }: {
    formRef: ComponentRef
    formSnapshot: FormSnapshot | undefined
    rawPreset: RawComponentStructre
    presetName: FormPreset
    createCustomFields: boolean
  }): Promise<ComponentRef> {
    const {
      structure: formStructure,
      ancestors,
      controllerRef,
    } = await this._createFormStructureADI({
      presetName,
      rawPreset,
      formRef,
      createCustomFields,
      formSnapshot,
    })

    await this.boundEditorSDK.components.remove({
      componentRef: formRef,
    })

    const { addedFormRef } = await this._addFormStructureADI({
      formStructure,
      ancestors,
    })

    await this._afterFormAddedADI({
      controllerRef,
      addedFormRef,
      formSnapshot,
    })

    return addedFormRef
  }

  private async _createFormStructureADI({
    presetName,
    rawPreset,
    formRef,
    createCustomFields,
    formSnapshot,
  }: {
    presetName: FormPreset
    rawPreset: RawComponentStructre
    formRef: ComponentRef
    createCustomFields: boolean
    formSnapshot: FormSnapshot
  }): Promise<{
    structure: ComponentStructre
    ancestors: ComponentRef[]
    controllerRef: ComponentRef
  }> {
    const structure = await this._createDynamicFormDataADI({
      presetName,
      rawPreset,
      formSnapshot,
      createCustomFields,
    })
    const [ancestors, { x, y }] = await Promise.all([
      this.boundEditorSDK.components.getAncestors({
        componentRef: formRef,
      }),
      this.boundEditorSDK.components.layout.get({ componentRef: formRef }),
    ])

    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: APP_CONTROLLER_DEFINITION,
      pageRef: MASTER_PAGE,
    })
    const { id: controllerId } = await this.boundEditorSDK.components.data.get({
      componentRef: controllerRef,
    })

    const formStructure = convertPreset(structure, { controllerId, coords: { x, y } })
    return { ancestors, structure: formStructure, controllerRef }
  }

  private async _createDynamicFormDataADI({
    presetName,
    rawPreset,
    formSnapshot,
    createCustomFields,
  }: {
    presetName: FormPreset
    rawPreset: RawComponentStructre
    formSnapshot: FormSnapshot
    createCustomFields: boolean
  }): Promise<RawComponentStructre> {
    if (formSnapshot && formSnapshot.formComponent) {
      return enhanceStructreWithSnapshot(rawPreset, formSnapshot)
    }
    const customFields = createCustomFields && (await this.coreApi.fields.getCustomFields())
    const roleEnhancmentMap = {
      [ROLE_FORM]: config => this._createFormConfig(config, presetName),
      ..._.reduce(
        FIELDS,
        (acc, role) => {
          acc[role] = config => this._enhanceField(config, customFields)
          return acc
        },
        {}
      ),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureADI({
    formStructure,
    ancestors,
  }: {
    formStructure: Object
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef }> {
    const addedFormRef = await this.boundEditorSDK.components.add({
      componentDefinition: formStructure,
      pageRef: ancestors[0],
    })
    return { addedFormRef }
  }

  private async _afterFormAddedADI({
    controllerRef,
    formSnapshot,
    addedFormRef,
  }: {
    controllerRef: ComponentRef
    formSnapshot: FormSnapshot
    addedFormRef: ComponentRef
  }) {
    this.coreApi.appState.setState([controllerRef])

    if (formSnapshot && _.get(formSnapshot, 'formComponent.config.collectionId')) {
      await this.updateFormCollectionId(
        addedFormRef,
        formSnapshot.formComponent.config.collectionId
      )
    }

    addedFormsPromisesContainer[addedFormRef.id] = new Promise(async resolve => {
      await this.coreApi.editDraft(addedFormRef)
      resolve()
    })
  }

  private async _addForm(
    presetName: FormPreset,
    { containerRef = null, source_name = null, select_form = true, createCollection = false } = {},
    formSnapshot?: FormSnapshot
  ): Promise<ComponentRef> {
    await this._saveSiteIfNecessary(containerRef)

    const initiator = this.coreApi.initiator()
    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(this.ravenInstance)(presetName, locale, failReason =>
      this.coreApi.logFetchPresetsFailed(null, failReason)
    )

    if (!rawPreset) {
      return
    }

    let formRef
    if (!isADI(initiator) && !isTemplateADI(initiator)) {
      formRef = await this._addFormEditor({
        formRef: containerRef,
        presetName,
        rawPreset,
        selectForm: select_form,
        createCollection,
      })
    } else {
      formRef = await this._addFormADI({
        formRef: containerRef,
        presetName,
        rawPreset,
        formSnapshot,
        createCustomFields: isADI(initiator),
      })
    }

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.CHOOSE_TEMPLATE,
      template: presetName,
      form_comp_id: formRef.id,
      source_name: source_name || initiator,
    })

    return formRef
  }

  private async _getFormName(nameFromPreset: string, presetKey: FormPreset): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const componentConnection = await this.coreApi.getComponentConnection(formRef)
        return _.get(componentConnection, 'config.formName', '')
      })
    )

    return normalizeFormName(formNames, nameFromPreset, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }
}
