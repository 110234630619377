import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { DEFAULT_RESTRICTIONS, PremiumRestriction } from '../../../constants/premium'

export default class PremiumApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  public async getPremiumRestrictions(): Promise<{ restrictions: PremiumRestriction }> {
    const fallbackResponse = Promise.resolve({ restrictions: DEFAULT_RESTRICTIONS })
    try {
      const isTemplate = await this.coreApi.isTemplate()

      if (isTemplate) {
        fallbackResponse
      } else {
        const res = await this.remoteApi.getPremiumRestrictions()
        return res || fallbackResponse
      }
    } catch (err) {
      return Promise.resolve({ restrictions: DEFAULT_RESTRICTIONS })
    }
  }
}
